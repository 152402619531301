<template>
  <picture>
    <source :srcset="'/' + imagem + '.webp'" type="image/webp" />
    <source :srcset="'/' + imagem + '.png'" type="image/png" />
    <img
      :class="props.class"
      :width="props.width"
      :height="props.height"
      :src="'/' + imagem + '.png'"
      :alt="imagem"
    />
  </picture>
</template>

<script setup>
const props = defineProps({
  class: String,
  imagem: String,
  width: String,
  height: String,
});
</script>

<script>
export default {
  name: "CustomPicture",
};
</script>
